<template>
  <div class="content-box">
    <div class="box-shadow">
      <div class="clearfix add-box">
        <el-form :inline="true" class="my_element_form">
          <el-form-item label="账号:">
            <el-input size="medium" v-model="searchName" placeholder="请输入账号" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="search">搜索</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="info" size="medium" @click="reset">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="success" size="medium" @click="addItem()">新增</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div style="padding-bottom: 25px;">
      <el-table :data="dataLists" :header-cell-style="{background: '#eee', color: '#333'}" style="width: 100%;" highlight-current-row stripe v-loading="loading" >
        <el-table-column label="账号" prop="loginName" align="center" min-width="200"></el-table-column>
        <!-- <el-table-column label="角色" prop="userType" align="center" min-width="250"> </el-table-column> -->
        <el-table-column label="创建时间" align="center" min-width="200">
          <template slot-scope="scope">{{scope.row.createDate}}</template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="350">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="setPower(scope.row.id, scope.row.name)">角色</el-button>
            <el-button size="mini" type="success" plain @click="setPwd(scope.row.id)">重置密码</el-button>
            <el-button size="mini" type="danger" plain @click="deleteUser(scope.row.id, scope.row.loginName)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @size-change="PaginationFun"
      @current-change="PaginationFun"
      :current-page.sync="pageNo"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="totalCount"
      background>
    </el-pagination>

    <!-- 设置权限 -->
    <el-dialog
      :title="dialogTitle"
      :visible="dialogVisible"
      width="30%"
      :show-close="false"
    >
      <div v-loading="dialogLoading" style="min-height:50px">
        <el-checkbox-group v-model="checkedRoles" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in allRoles" :label="item.id" :key="item.id" size="medium" :disabled="item.id==0">{{item.name}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn-search" style="margin-right: 15px;" @click.prevent="submit()">确 定</div>
        <div class="btn-cancel" @click.prevent="clearSubmit()">取 消</div>
      </span>
    </el-dialog>
    <!-- 新增/编辑 -->
    <el-dialog
      title="用户"
      :visible="isShow"
      width="30%"
      :show-close="true"
      @close="userClearSubmit()"
    >
      <el-form
        ref="userItem"
        :model="userItem"
        class="demo-form-inline"
        label-width="100px"
        :rules="userRules"
        v-loading="userLoading"
      >
        <el-form-item label="账号：" prop="phone">
          <el-input v-model="userItem.phone" :disabled="userOpePattern === 'edit'"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <el-input v-model="userItem.password"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div class="btn-search" style="margin-right: 15px;" @click.prevent="userSubmit()">确 定</div>
        <div class="btn-cancel" @click.prevent="userClearSubmit()">取 消</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import md5 from 'js-md5'
import { AdminPersonnelManagementList, AdminUserResetPwd, AdminGetUserRoles, AdminGetAllRoleList, AdminUserRoleUpdate, AdminUserAdd, AdminDeleteUser } from '@/api/adminApi'
export default {
  name: 'SettingUser',
  data () {
    return {
      searchName: '',
      totalPage: 0, // 总页数
      totalCount: 0, // 总条数
      pageNo: 1, // 当前页数
      pageSize: 10, // 每页几条
      dataLists: [],
      dialogVisible: false,
      allRoles: [],
      itemRoles: [],
      checkedRoles: [],
      submitCheckedRoles: [],
      submitId: '',
      dialogTitle: '角色',
      dialogLoading: false,
      loading: false,
      // 新增编辑弹框数据
      userOpePattern: '', // add edit
      isShow: false,
      userLoading: false,
      userItem: {
        id: '',
        phone: '',
        password: ''
      },
      userRules: {
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  components: {},
  created () {
    this.getLists()
  },
  methods: {
    reset () {
      this.searchName = ''
      this.pageNo = 1
      this.getLists()
    },
    search () {
      this.pageNo = 1
      this.getLists()
    },
    getLists () {
      this.loading = true
      const item = {}
      item.UserListPagePo = {
        loginName: this.searchName
      }
      item.PageInfo = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      AdminPersonnelManagementList(item).then(resp => {
        this.loading = false
        if (resp.code === '000') {
          this.totalCount = resp.result.count
          this.totalPage = resp.result.pageNo
          this.pageNo = resp.result.pageSize
          this.dataLists = resp.result.list
        } else {
          this.$message({ message: resp.msg, type: 'error' })
          this.dataLists = []
        }
      })
    },
    setPwd (id) {
      this.$confirm('确定重置该用户密码?').then(() => {
        const item = {}
        item.id = id
        AdminUserResetPwd(item).then(resp => {
          if (resp.code === '000') {
            this.$confirm('密码已经重置为：a1234567', '提示', {
              confirmButtonText: '确定'
            }).then(res => {}).catch(() => {})
          } else {
            this.$message({ message: resp.msg, type: 'error' })
          }
        })
      }).catch(() => {})
    },
    // 删除用户
    deleteUser (id, name) {
      this.$confirm(`确定删除该用户"${name}"?`).then(() => {
        this.loading = true
        AdminDeleteUser({ ids: id }).then((resp) => {
          this.loading = false
          if (resp.code === '000') {
            this.getLists()
            this.$message({ message: '删除成功', type: 'success' })
          } else {
            this.$message({ message: resp.msg, type: 'error' })
          }
        })
      }).catch(() => {})
    },
    setPower (id, name) {
      this.submitId = id
      // this.dialogTitle = name + '-角色管理'
      this.dialogTitle = '角色管理'
      this.dialogVisible = true
      this.dialogLoading = true
      if (this.allRoles.length === 0) {
        AdminGetAllRoleList({}).then((res) => {
          if (res.code === '000') {
            this.allRoles = [...res.result]
            AdminGetUserRoles({ id: id }).then((resp) => {
              this.dialogLoading = false
              if (resp.code === '000') {
                this.itemRoles = [...resp.result]
                this.checkedRoles = this.itemRoles.map((item) => {
                  return item.id
                })
                this.submitCheckedRoles = this.itemRoles.map((item) => {
                  return item.id
                })
              } else {
                this.$message({ message: resp.msg, type: 'error' })
                this.itemRoles = []
                this.checkedRoles = []
                this.submitCheckedRoles = []
              }
            })
          } else {
            this.$message({ message: res.msg, type: 'error' })
          }
        })
      } else {
        AdminGetUserRoles({ id: id }).then(resp => {
          this.dialogLoading = false
          this.checkedRoles = []
          this.submitCheckedRoles = []
          if (resp.code === '000') {
            this.itemRoles = [...resp.result]
            this.checkedRoles = this.itemRoles.map((item) => {
              return item.id
            })
            this.submitCheckedRoles = this.itemRoles.map((item) => {
              return item.id
            })
          } else {
            this.itemRoles = []
            this.checkedRoles = []
            this.submitCheckedRoles = []
          }
        })
      }
    },
    clearSubmit () {
      this.dialogVisible = false
      this.checkedRoles = []
      this.submitCheckedRoles = []
    },
    submit () {
      this.dialogLoading = true
      AdminUserRoleUpdate({
        id: this.submitId,
        roleIds: this.submitCheckedRoles.join(',')
      }).then(resp => {
        this.dialogLoading = false
        if (resp.code === '000') {
          this.$message({ message: '操作成功', type: 'success' })
          this.getLists()
          this.dialogVisible = false
        } else {
          this.$message({ message: resp.msg, type: 'error' })
        }
      })
    },
    getAllRoles () {
      return AdminGetAllRoleList({})
    },
    getItemRoles (id) {
      return AdminGetUserRoles({ id: id })
    },
    handleCheckedCitiesChange (value) {
      this.submitCheckedRoles = value
    },
    PaginationFun (val) {
      this.pageNo = val
      this.getLists()
    },
    // 新增编辑用户
    addItem () {
      this.isShow = true
      this.userLoading = false
      this.userOpePattern = 'add'
    },
    userClearSubmit () {
      this.isShow = false
      this.userItem.id = ''
      this.userItem.phone = ''
      this.userItem.password = ''
    },
    userSubmit () {
      this.$refs.userItem.validate((valid) => {
        if (valid) {
          this.userLoading = true
          const data = {}
          data.loginName = this.userItem.phone
          if (this.userOpePattern === 'add') {
            data.password = md5(this.userItem.password)
            data.userType = 1
            AdminUserAdd(data).then(resp => {
              this.userLoading = false
              if (resp.code === '000') {
                this.$message({ message: '操作成功', type: 'success' })
                this.userClearSubmit()
                this.getLists()
              } else {
                this.$message({ message: resp.msg, type: 'error' })
              }
            })
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-checkbox.is-bordered+.el-checkbox.is-bordered{
  margin-left: 0px;
  margin-bottom: 10px;
}
/deep/.el-checkbox{
  margin-bottom: 10px;
}
.mg-b-s{
  margin-bottom: 10px;
}
.search-input {
  width: 200px;
  margin-right: 20px;
}
.btns{
  height: 50px;
  background-color: #c3d9ec;
  position: absolute;
  top: 0;
  right: -450px;
  bottom: 0;
  margin: auto;
  z-index: 10;
  text-align: right;
  padding: 8px 15px 0 0;
  border-radius: 8px 0 0 8px;
  transition: all .4s ease;
}
.btns-site{
  right: 0;
}
</style>
